  body{
    background-color: #ECECEC;
  }
  .login-container{
      margin: 5%;
     
  }
  .login-form-1{
      border-radius: 5px;
      box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
      padding: 5%;
      background-color: #FFF;
  }
  .link {
    display:block;
    color: #FF006E;
    font-size: calc(10px + 2vmin);
    width: 100%;
    margin-top: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: center ;
  }
  .link:visited {
    color: #FF006E;
    
  }
  
  .btnSubmit
  {
      width: 100%;
      border-radius: 5px;
      padding: 1.5%;
      border: none;
      cursor: pointer;
      color: #FFF;
      font-size: calc(10px + 2vmin);
      font-weight: 600;
      background-color: #61B9E2;


    }

  .combo{
    background-color: #FFF;
    font-size: calc(8px + 1vmin);
    color: #FF006E;
    margin-top: 10px
  }
 
  
  .header {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #FF006E;
    margin-top: 10px
  }
  .footer {
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #FF006E;
    margin-top: 10px
  }
  
  .title{
    background-color: #FFF;
    text-align: center;

    justify-content: center;
    font-size: calc(10px + 3vmin);
    color: #61B9E2;
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: 600;
  }
